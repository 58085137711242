<template>
  <el-dialog
    :visible.sync="editCourseDialog"
    :before-close="cancel"
    :close-on-click-modal="false"
    title="编辑文件"
    width="600px"
  >
    <el-form
    :model="editCourseForm"
    :rules="dataRule"
      ref="dataForm"
       label-width="120px"
       >
      <el-form-item label="学习开始日期" prop="startTime">
        <el-date-picker
          style="width: 274px"
          v-model="editCourseForm.startTime"
          type="datetime"

          placeholder="请选择"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="学习结束日期" prop="endTime">
        <el-date-picker
          style="width: 274px"
          v-model="editCourseForm.endTime"

          type="datetime"
          placeholder="请选择"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="带教老师" class="time">
        <el-select
          v-model="editCourseForm.lecturerTeamId"
          @change="changelecturerTeam"
          placeholder="请选择"
        >
          <el-option
            v-for="item in teacherTeamList"
            :key="item.id"
            :label="item.lecturerTeam"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <el-select v-model="editCourseForm.instructorId" placeholder="请选择">
          <el-option
            v-for="item in teachUserList"
            :key="item.employeeId"
            :label="item.employeeName"
            :value="item.employeeId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上课提醒" class="time" v-if="currentItem.type == 2">
        考试前
        <el-select  style="width:65px;" v-model="editCourseForm.reminderMinutes" placeholder="请选择">
          <el-option
            v-for="item in timeList"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
        分钟提醒
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$parent.editCourseDialog = false">取 消</el-button>
      <el-button type="primary" @click="editSubmitForm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editOrUpdateApi } from '@/api/study.js'
import { getTeachUserListApi, getTeachTeamListApi } from '@/api/settings'
import fileType from '@/mixins/fileType'
export default {
  mixins: [fileType],
  props: {
    editCourseDialog: {
      type: Boolean,
      default: false
    },
    currentItem: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      // 编辑课程form
      editCourseForm: {
        startTime: '',
        endTime: '',
        instructorType: '',
        reminderMinutes: '5',
        lecturerTeamId: '', // 讲师团队id
        instructorId: '', // 带教老师ID
        instructorName: '' // 带教老师名称
      },
      teacherTeamList: [], // 讲师团队
      teachUserList: [], // 讲师列表
      timeList: ['5', '10', '15', '20', '30'],
      dataRule: {
        startTime: [
          {
            required: true,
            message: '请选择学习开始日期',
            trigger: 'change'
          }
        ],
        endTime: [
          {
            required: true,
            message: '请选择学习结束日期',
            trigger: 'change'
          }
        ]
      }
    }
  },
  created () {
    this.getTeachTeamList()
    this.editCourseForm = JSON.parse(JSON.stringify(this.currentItem))
    // this.editCourseForm.reminderMinutes =

    this.getTeachUserList(this.editCourseForm.lecturerTeamId)
  },
  methods: {
    cancel () {
      // 取消
      this.$parent.editCourseDialog = false
    },
    changelecturerTeam (value) {
      this.$set(this.editCourseForm, 'instructorId', '')
      this.getTeachUserList(value)
    },
    getTeachTeamList () {
      getTeachTeamListApi().then((res) => {
        if (res.code === 200) {
          this.teacherTeamList = res.data
        }
      })
    },
    getTeachUserList (lecturerTeamId) {
      getTeachUserListApi({
        lecturerTeamId
      }).then((res) => {
        if (res.code === 200) {
          this.teachUserList = res.object.results
          const obj = this.teachUserList.find(item => item.employeeId == this.editCourseForm.instructorId)
          console.log(obj)
          if (obj && obj.employeeId) {
            this.$set(this.editCourseForm, 'instructorId', String(this.editCourseForm.instructorId))
          } else {
            this.$set(this.editCourseForm, 'instructorId', '')
            this.$set(this.editCourseForm, 'instructorName', '')
          }
        }
      })
    },
    // 编辑阶段确定
    editSubmitForm () {
      this.$refs.dataForm.validate((valid) => {
        if (valid) {
          if (this.editCourseForm.startTime && this.editCourseForm.endTime) {
            if (new Date(this.editCourseForm.startTime).getTime() > new Date(this.editCourseForm.endTime).getTime()) {
              this.$message.error('开始时间不能大于结束时间！')
              return
            }
          }
          this.teachUserList.forEach(item => {
            if (this.editCourseForm.instructorId === item.employeeId) {
              this.editCourseForm.instructorName = item.employeeName
            }
          })
          this.editCourseForm.startTime = this.computedDate(this.editCourseForm.startTime)
          this.editCourseForm.endTime = this.computedDate(this.editCourseForm.endTime)

          editOrUpdateApi({ ...this.editCourseForm }).then((res) => {
            if (res.code === 200) {
              this.$parent.editCourseDialog = false
              this.$parent.getClassList()
              this.$message.success('操作成功！')
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
