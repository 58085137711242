/*
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2024-02-02 14:40:03
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-13 11:26:11
 */
import { post, get } from '@/utils/request'
const path = '/train'
const API = {
  // 获取自定义设置
  getFindCustomizeType: path + '/trCustomizeType/findCustomizeType',
  // 自定义提交
  submitFindCustomizeType: path + '/trCustomizeType/insertCustomizeType',
  // 删除接口
  deleteFindCustomizeType: path + '/trCustomizeType/removeType',
  // 权限查询
  queryPermissions: path + '/hrAdminConfiguration/findByPage',
  // 权限删除
  deletePermissions: path + '/hrAdminConfiguration/remove',
  // 权限新增
  permissionsAdd: path + '/hrAdminConfiguration/createOrUpdate',
  // 讲师团队-左侧
  getTeachTeamList: path + '/lecturerTeam/findByList', // 左侧分组讲师列表
  getTeachTeamDetail: path + '/lecturerTeam/details', // 左侧分组讲师--详情
  getTeachTeamAddOrEdit: path + '/lecturerTeam/createOrUpdate', // 左侧分组讲师--添加和修改
  getTeachTeamDelete: path + '/lecturerTeam/remove', // 左侧分组讲师--批量删除
  // 讲师团队-右侧
  getTeachUserList: path + '/teacherTeam/findByPage', // 右侧讲师列表
  getTeachUserDetail: path + '/teacherTeam/details', // 右侧讲师--详情
  getTeachUserAddOrEdit: path + '/teacherTeam/createOrUpdate', // 右侧讲师--添加和修改
  getTeachUserDelete: path + '/teacherTeam/remove', // 右侧讲师--批量删除
  // 负责人
  getResponseUserList: path + '/headConfiguration/findByPage', // 负责人列表
  getResponseUserDetail: path + '/headConfiguration', // 负责人--详情
  getResponseUserAddOrEdit: path + '/headConfiguration/createOrUpdate', // 负责人--添加和修改
  getResponseUserDelete: path + '/headConfiguration/remove' // 负责人--批量删除

}
export const permissionsAddApi = (data) => post(API.permissionsAdd, data)
export const deletePermissionsApi = (data) => post(API.deletePermissions, data)
export const queryPermissionsApi = (data) => post(API.queryPermissions, data)
export const getFindCustomizeTypeApi = (data) => get(API.getFindCustomizeType, data)
export const submitFindCustomizeTypeApi = (data) => post(API.submitFindCustomizeType, data)
export const deleteFindCustomizeTypeAPi = (data) => post(API.deleteFindCustomizeType, data)
// // 讲师团队-左侧
export const getTeachTeamListApi = (data) => post(API.getTeachTeamList, data)// 左侧分组讲师列表
export const getTeachTeamDetailApi = (data) => get(API.getTeachTeamDetail, data)// 左侧分组讲师--详情
export const getTeachTeamAddOrEditApi = (data) => post(API.getTeachTeamAddOrEdit, data)// 左侧分组讲师--添加和修改
export const getTeachTeamDeleteAPi = (data) => post(API.getTeachTeamDelete, data)// 左侧分组讲师--批量删除
// 讲师团队-右侧
export const getTeachUserListApi = (data) => post(API.getTeachUserList, data)// 右侧讲师列表
export const getTeachUserDetailApi = (data) => get(API.getTeachUserDetail, data)// 右侧讲师--详情
export const getTeachUserAddOrEditApi = (data) => post(API.getTeachUserAddOrEdit, data)// 右侧讲师--添加和修改
export const getTeachUserDeleteAPi = (data) => post(API.getTeachUserDelete, data)// 右侧讲师--批量删除
// 负责人
export const getResponseUserListApi = (data) => post(API.getResponseUserList, data)// 负责人列表
export const getResponseUserDetailApi = (data) => get(API.getResponseUserDetail, data)// 负责人--详情
export const getResponseUserAddOrEditApi = (data) => post(API.getResponseUserAddOrEdit, data)// 负责人--添加和修改
export const getResponseUserDeleteAPi = (data) => post(API.getResponseUserDelete, data)// 负责人--批量删除
